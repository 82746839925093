import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import {ARTICLE_CODES} from "../config-global";


const codesLiIcons = [
  {code: '&li21001;' , icon: "noto-v1:check-box-with-check" } ,
  {code: '&li21003;' , icon: "noto-v1:check-mark" } ,
  {code: '&li21004;' , icon: "noto-v1:cross-mark" } ,
  {code: '&li20001;' , icon: "noto:check-mark-button" } ,
  {code: '&li20003;' , icon: "noto:cross-mark" } ,
  {code: '&li30001;' , icon: "streamline-emojis:ballot-box-with-check" } ,
  {code: '&li30002;' , icon: "streamline-emojis:open-book" } ,
  {code: '&li30003;' , icon: "streamline-emojis:calendar" } ,
  {code: '&li30005;' , icon: "streamline-emojis:cross-mark" } ,
    {code: '&li30006;' , icon: "streamline-emojis:direct-hit" } ,
    {code: '&li30007;' , icon: "streamline-emojis:open-file-folder" } , // папка
    {code: '&li30008;' , icon: "streamline-emojis:dollar-banknote" } ,// деньги
    {code: '&li30009;' , icon: "streamline-emojis:clipboard" } , // планшет для бумаги
    {code: '&li30010;' , icon: "streamline-emojis:scroll" } , // лист бумаги
    {code: '&li30011;' , icon: "streamline-emojis:newspaper" } , // газета
    {code: '&li30012;' , icon: "streamline-emojis:wrapped-gift-1" } , // закрытый подарок
    {code: '&li30013;' , icon: "streamline-emojis:bomb" } , // бомба
    {code: '&li30014;' , icon: "streamline-emojis:bar-chart" } , // график
    {code: '&li30015;' , icon: "streamline-emojis:pill" } , // таблетка
    {code: '&li30016;' , icon: "logos:youtube-icon" } , // кнопка ютуб
    {code: '&li30017;' , icon: "logos:telegram" } , // лого телеграм
    {code: '&li30018;' , icon: "cib:vk" } , // лого вк
    {code: '&li30019;' , icon: "noto:fire" } , // огонь
    {code: '&li30020;' , icon: "noto:red-exclamation-mark" } , // восклицительный знак
    {code: '&li30021;' , icon: "noto:backhand-index-pointing-down" } , // палец вниз
    {code: '&li01;' , icon: "noto-v1:timer-clock"} ,  // часы
    {code: '&li02;' , icon: "noto-v1:shopping-cart"} ,  // корзина
    {code: '&li03;' , icon: "emojione:money-bag"} ,  // мешок с деньгами
    {code: '&li04;' , icon: "openmoji:check-mark"} ,  // галка зеленая с обводкой черной
    {code: '&li05;' , icon: "vscode-icons:file-type-video"} ,  // видео пленка красная
    {code: '&li06;' , icon:  "noto:man-gesturing-no-light-skin-tone"} ,
    {code: '&li07;' , icon:  "noto:man-pouting-light-skin-tone"} ,
    {code: '&li08;' , icon:  "noto:man-raising-hand-light-skin-tone"} ,
    {code: '&li09;' , icon:  "noto:man-getting-massage-light-skin-tone"} ,
    {code: '&li10;' , icon:  "noto:handshake-light-skin-tone-medium-light-skin-tone"} ,
    {code: '&li11;' , icon:  "noto:money-bag"} ,
    {code: '&li12;' , icon:  "noto:bank"} ,
    {code: '&li13;' , icon:  "noto:handshake"} ,

    {code: '&li41;' , icon: "noto:1st-place-medal" } ,
    {code: '&li42;' , icon: "noto:2nd-place-medal" } ,
    {code: '&li43;' , icon: "noto:3rd-place-medal" } ,
    {code: '&li44;' , icon: "emojione:briefcase"} ,
    {code: '&li45;' , icon: "emojione:memo"} ,
    {code: '&li46;' , icon: "noto:microscope"} , // микроскоп
    {code: '&li47;' , icon: "noto:dna"} , // днк
    {code: '&li48;' , icon: "noto:memo"} , // карандаш и бумага
    {code: '&li49;' , icon: "noto:office-building"} , // здание
    {code: '&li50;' , icon: "noto:scroll"} , // сверток
    {code: '&li51;' , icon: "noto:shopping-bags"} , // пакеты с покупками
    {code: '&li52;' , icon: "noto:test-tube"} , // пробирка
    {code: '&li53;' , icon:  "hugeicons:halal"} , // халяль
    {code: '&li54;' , icon:  "noto:red-question-mark"} , // красный знак вопроса
    {code: '&li55;' , icon:  "noto:globe-showing-asia-australia"} , // земля
    {code: '&li56;' , icon:  "solar:verified-check-line-duotone"} , // черная check

  {code: '&li57;' , icon:  "emojione:airplane"} , // самолет <Icon icon="emojione:airplane" />
  // {code: '&li58;' , icon:  "solar:verified-check-line-duotone"} , // будильник
  {code: '&li59;' , icon:  "emojione:baby-bottle"} , // детская бутылка
  {code: '&li60;' , icon:  "emojione-v1:necktie"} , // рубашка галстук
  {code: '&li61;' , icon:  "noto-v1:check-mark"} , // голубая check

    {code: '&li31;' , icon: "emojione-v1:orange-book"} ,
    // <Icon icon="emojione:memo" />
    {code: '&li21;' , icon: "streamline:decent-work-and-economic-growth-solid"} ,
    {code: '&li22;' , icon: "fxemoji:housebuilding" } ,
    {code: '&li23;' , icon: "fluent-emoji:office-building" } ,
    {code: '&li24;' , icon: "clarity:contract-solid" } ,
    {code: '&li25;' , icon: "streamline-emojis:money-bag"} ,
    {code: '&li26;' , icon: "streamline-emojis:spaghetti"} ,
    {code: '&li27;' , icon: "streamline-emojis:clinking-glasses-5"} ,
    {code: '&li28;' , icon: "streamline-emojis:speedboat"} ,
    {code: '&li29;' , icon: "streamline-emojis:locked-with-key"} ,
    {code: '&li30;' , icon: "fluent-emoji-flat:coin"} ,
    {code: '&li32;' , icon: "noto-v1:admission-tickets"} ,
    {code: '&li33;' , icon: "material-symbols:check" } ,
    {code: '&li34;' , icon: "simple-line-icons:check"} ,
    {code: '&li35;' , icon: "material-symbols-light:check"} ,
    {code: '&num1;' , icon: "fluent-emoji-flat:keycap" } ,
    {code: '&num2;' , icon: "noto:keycap" } ,
    {code: '&num3;' , icon: "noto-v1:keycap" } ,
];

export function getACodes(code){
    // console.log("CODE >>>" , code);
    const _code = ARTICLE_CODES.filter(el => el.code === code );
    if(Array.isArray(_code) && _code.length > 0) {
        return _code
    }
    return false;
}

export function getLiCodes(code ){

  const _code = codesLiIcons.filter(el => el.code === code );
  if(Array.isArray(_code) && _code.length > 0) {
    return _code
  }
  return false;
}

export function replaceButtonCode(text){


    // const text = props.node.children[0].value;
    const reqxBtnCode = /&b([0-9]+);/g;
    const regxIcoCode = /&i(.*);/g

    // console.log("replaceButtonCode TEXT " , text);
    if(typeof text !== 'string'){
        return false;
    }

    const resBtnCode = text ? [...text.matchAll(reqxBtnCode)] : [] ;
    const resIcoCode = text ? [...text.matchAll(regxIcoCode)] : [] ;

    let resObj  = false;

    if(resBtnCode.length > 0){
        const _code = getACodes(resBtnCode[0][0])
        const txt = text.replaceAll(resBtnCode[0][0] , '')
        const {variant} = _code[0].set;
        const {severity} = _code[0].set;
        resObj =  {text: txt , variant , color: severity}

        if(resIcoCode.length > 0){
            // console.log("res Ico code"  , resIcoCode[0].index);

          const finText = txt.replaceAll(resIcoCode[0][0] , '');
          const indexFinText = text.indexOf(finText);
          const indexCode = resIcoCode[0].index;
          // найдем с какой стороны расположен код иконки

          const pos = indexCode > indexFinText ? "end" : "start";
          resObj = {...resObj , text: finText ,  icon: { position: pos , code: resIcoCode[0][1] }}
        }
    }
    return resObj;
}


export function replaceIconCode( text , index= 1 , nodefault = false ) {

  const defaultIcon = !nodefault ?  { text , icon: ["noto-v1:check-box-with-check"]} : null;
  const reqxIconCode = /&li([0-9]+);/g;
  const reqxNumIconCode = /&num([0-9]+);/g;
  const resIconCode = text ? [...text.matchAll(reqxIconCode)] : [];

  if (resIconCode.length > 0) {
    const _code = getLiCodes(resIconCode[0][0])

    if (_code) {
      const txt = text.replaceAll(resIconCode[0][0], '')
      return {text: txt , icon: [_code[0].icon]};
    }
  }else{
      const resNumCode = text ? [...text.matchAll(reqxNumIconCode)] : [];

      if (resNumCode.length > 0) {
          const numCode = getLiCodes(resNumCode[0][0])
          if (numCode) {
            const num = index+1;
            let arIcon = [`${numCode[0].icon}-${num}`];
            if(num > 9){
                const numStr = num.toString();
                const arNum = numStr.split('');

                arIcon = arNum.map(el => `${numCode[0].icon}-${el}`)

            }
              const txt = text.replaceAll(resNumCode[0][0], '')
              return {text: txt , icon: arIcon};
          }
      }else{
          return defaultIcon;
      }
  }
    return defaultIcon;
}
